<template>
  <div>
    <v-container>
      <vue-snotify></vue-snotify>
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="mt-10 mb-10">
            <form enctype="multipart/form-data">
              <v-card-title class="cyan darken-1">
                <span class="white--text">Crear Equipo</span>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-row>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="name" label="Nombre *" :rules="nameRules" ></v-text-field>
                  <v-checkbox @change="checked" v-model="chk_promotion" label="Promoción">
                  </v-checkbox>
                  <div id="txt_price" style="display: block">
                    <v-text-field prefix="$" v-model="price" label="Precio *" type="number" :rules="priceRules"></v-text-field>
                    <v-text-field prefix="$" v-model="previous_price" label="Precio Anterior *" type="number" :rules="priceRules"></v-text-field>
                  </div>
                  <v-file-input v-model="image" accept="image/png, image/jpeg, image/bmp"
                    placeholder="Inserte una imagen" prepend-icon="mdi-camera" label="Imagen de equipo *" :rules="imageRules">
                  </v-file-input>
                  <v-textarea v-model="description" label="Descripción *"></v-textarea>
                </v-col>
                <v-col cols="12" md="3"></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-divider class="mt-10 mb-10"></v-divider>
                </v-col>
                <v-col cols="12" md="8"></v-col>
                <v-col cols="12" md="4">
                  <div class="text-right pr-15 mb-10">
                    <v-btn color="primary" class="" outlined @click="cancel">
                      <v-icon>mdi-close-circle</v-icon>
                      <span class="ml-1">Cancelar</span>
                    </v-btn>
                    <v-btn color="primary" class="mr-4 ml-4" outlined @click="create">
                      <v-icon>mdi-check-circle</v-icon><span class="ml-1">Crear</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    chk_promotion: false,
    name: "",
    price: "",
    previous_price: "",
    image: null,
    description: "",

    nameRules: [
      v => !!v || 'El nombre es requerido',
      v=>v.length <= 25 || "El campo nombre no debe exceder de 25 caracteres",
    ],
    priceRules: [
      v => !!v || 'El precio es requerido',
      v => v >= 0 || 'El valor precio debe ser mayor o igual a cero',
    ],
    imageRules: [
      v => !!v || 'La imagen es requerida',      
    ],
    descriptionRules: [
      (v) =>
        (v.length <= 500) || "El campo no debe exceder de 500 caracteres",
    ],


  }),
  methods: {
    cancel() {
      this.$router.push({ name: "view-equipments" });
    },
    checked() {
      if (this.chk_promotion) {
        document.getElementById("txt_price").style.display = "none";
        this.chk_promotion = false;
      } else {
        document.getElementById("txt_price").style.display = "block";
        this.chk_promotion = true;
      }
    },
    create() {
      /* let request = {
         name: this.name,
         price: this.price,
         previous_price: this.previous_price,
         image: this.image,
         description: this.description,
       }*/
      let formData = new FormData();
      formData.append("name", this.name);
      if (this.chk_promotion) {
        formData.append("price", 0);
        formData.append("previous_price", 0);
      } else {
        formData.append("price", this.price);
        formData.append("previous_price", this.previous_price);
      }
      formData.append("image", this.image);
      formData.append("description", this.description);
      console.log("imprime imagen");
      console.log(this.image);
      axios
        .post("/save-equipment", formData)
        .then((response) => {
          console.log(response);
          this.displayNotification(
            "success",
            "Éxito",
            "Se ha creado correctamente el equipo"
          );
          setTimeout(
            () => this.$router.push({ name: "view-equipments" }),
            4000
          );
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error en la inserción de equipo"
          );
          setTimeout(
            () => this.$router.push({ name: "view-equipments" }),
            4000
          );
        });
    },
  },
};
</script>